// 引入request
import requests from '../../Utils/request'

// 获取团队数据
export const getTeam = () => requests({
  url: '/api/team/detail',
  method: 'get',
})

// 创建团队
export const addTeam = (data) =>
  requests({
    url: `/api/team/add`,
    method: "post",
    data,
  });

// 编辑团队
export const editTeam = (data) =>
  requests({
    url: `/api/team/edit`,
    method: "post",
    data,
  });
