<template>
  <div class="team">
    <div class="head-box">我的团队</div>
    <div class="content-box">
      <div class="card-box">
        <!-- <p class="title-p">团队介绍</p> -->
        <div class="row-box">
          <span>律所标志</span>
          <div class="add-box" v-show="!showImg">
            <el-upload
              class="avatar-uploader"
              :action="objData.host"
              :data="objData"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              :on-success="handleSuccess"
            >
              <img src="@/assets/serve-report/add-icon.png" alt="" />
              <p>点击上传</p>
            </el-upload>
          </div>
          <div
            v-if="showImg"
            class="file-card"
            @mouseenter="showOperation = true"
            @mouseleave="showOperation = false"
          >
            <div class="img-box">
              <img :src="`https://file-public.ilaas.cn/${showImg}`" alt="" />
              <div class="btn-box" v-if="showOperation">
                <button @click="delFile(fileInfo)">删除</button>
              </div>
            </div>
          </div>
        </div>
        <div class="row-box">
          <span>律所名称</span>
          <el-input
            v-model="myInfo.lawOfficeName"
            placeholder="请输入律所名称"
          ></el-input>
        </div>
        <div class="row-box">
          <span>团队名称</span>
          <el-input
            v-model="myInfo.name"
            placeholder="请输入团队名称"
          ></el-input>
        </div>
        <div class="row-box row-box2">
          <span>团队简介</span>
          <el-input
            type="textarea"
            placeholder="请输入团队简介"
            maxlength="1000"
            :autosize="{ minRows: 5 }"
            v-model="myInfo.profile"
            show-word-limit
          >
          </el-input>
        </div>
      </div>
      <div class="card-box">
        <p class="title-p">成员简介</p>
        <el-button @click="add" v-if="memberList.length < 7">新增成员</el-button>
        <div class="member-cards">
          <div
            class="member"
            v-for="(item, index) in memberList"
            :key="index"
            @mouseenter="mouseenter(index)"
            @mouseleave="mouseleave"
            @click="edit(item)"
          >
            <i
              class="el-icon-circle-close"
              v-if="isMemberIdx === index"
              @click="delMember(index)"
            ></i>
            <div class="img-box">
              <img
                :src="`https://file-public.ilaas.cn/${item.avatar}`"
                alt=""
              />
            </div>
            <div class="right-box">
              <div class="name-box">
                <span :title="item.name">{{ item.name }}</span>
                <span :title="item.position">{{ item.position }}</span>
              </div>
              <p>{{ item.profile }}</p>
            </div>
          </div>
        </div>
      </div>
      <el-button @click="submit">保存</el-button>
    </div>
    <addMemberDialog v-if="showDialog" @setMember="setMember" :info="dialogInfo"></addMemberDialog>
  </div>
</template>

<script>
import { core } from "../config/pluginInit";
import { getPolicyToken, getUser } from "@/FackApi/api/index";
import { addTeam, editTeam, getTeam } from "@/FackApi/api/team";
import addMemberDialog from "@/views/Dialog/addMemberDialog.vue";
var OSS = require("ali-oss");
import { mapGetters } from "vuex";
export default {
  name: "team",
  components: { addMemberDialog },
  data() {
    return {
      myInfo: {
        lawOfficeLogo: "",
        lawOfficeName: "",
        name: "",
        profile: "",
        members: [],
      },
      ossParams: {
        // oss参数
        expireTime: 120,
        uploadDir: "iLaaSWorkbench/team/",
      },
      ossInfo: {},
      filePathStr: "", // 文件上传成功的路径
      objData: {
        OSSAccessKeyId: "",
        policy: "",
        Signature: "",
        key: "",
        host: "",
        dir: "",
      },
      fileInfo: {},
      showOperation: false, // 鼠标移入显示操作按钮
      showImg: "",
      memberList: [],
      showDialog: false,
      dialogInfo: {
        type: 'add',
      },
      isMemberIdx: "", // 鼠标移入的成员下标
    };
  },
  created() {
    this.getTeamInfo();
    this.getPolicyTokenInfo();
  },
  mounted() {
    core.index();
    // console.log(this.userInfo);
    // this.$nextTick(()=>{
    //   console.log(this.userInfo);
    //   this.myInfo.lawOfficeName = this.userInfo.enterprise;
    // })
  },
  computed: {
    ...mapGetters({
      userInfo: "index/userInfoState",
    }),
  },
  methods: {
    // 获取团队数据
    async getTeamInfo() {
      let newName = '';
      let res1 = await getUser();
      if (res1.code === 0) {
        newName = res1.data.enterprise;
      }
      let res = await getTeam();
      console.log(res);
      if (res.code == 0 && JSON.stringify(res.item) !== 'null') {
        this.myInfo = res.item;
        this.showImg = this.myInfo.lawOfficeLogo || "";
        this.memberList = this.myInfo.members;
      } else {
        this.myInfo.lawOfficeName = newName;
      }
    },
    // 上传客户文件前的操作
    async beforeAvatarUpload(file) {
      let newArr = file.name.split(".");
      let fileType = newArr[newArr.length - 1];
      let newFileName = file.name;
      let imgType = [
        "jpg",
        "jpeg",
        "png",
        "pnge",
        "dds",
        "psd",
        "pdt",
        "webp",
        "xmp",
        "bmp",
        "svg",
        "tiff",
        "ico",
      ];
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!imgType.includes(fileType)) {
        this.$message.warning("上传文件只能是图片格式!");
        return;
      }
      if (!isLt5M) {
        this.$message.warning("上传文件大小不能超过 5MB!");
        return;
      }
      this.objData = {};
      let data = this.ossInfo;
      this.objData = {
        OSSAccessKeyId: data.accessid,
        policy: data.policy,
        signature: data.signature,
        expire: data.expire,
        host: data.host,
        dir: data.dir,
        key: `${data.dir}/${new Date().getTime()}-${newFileName}`,
      };
      console.log(this.objData);
      this.filePathStr = this.objData.key;
      this.myInfo.title = newFileName;
      this.myInfo.filePath = this.objData.key;
      this.fileInfo = {
        name: newFileName,
        filePath: this.objData.key,
        imgUrl: `${this.objData.key}`, // https://file-public.ilaas.cn ： oss公共桶的链接地址
      };
    },
    // 上传成功
    handleSuccess(response, file, fileList, rowInfo) {
      this.showImg = this.fileInfo.imgUrl;
    },
    // 删除文件
    delFile(info) {
      this.$confirm(`请确认是否删除文件`)
        .then((_) => {
          this.delOssFile();
        })
        .catch((_) => {});
    },
    async delOssFile() {
      this.fileInfo = {};
      this.myInfo.filePath = "";
      let client = new OSS({
        region: "oss-cn-shenzhen",
        accessKeyId: this.ossInfo.accessid,
        accessKeySecret: "5ZUDWAJ0Gz4o52kubRVYCaJRRQRims",
        bucket: "ilaas-public",
      });
      let res = await client.delete(this.filePathStr);
      this.filePathStr = "";
      this.showImg = "";
    },
    // 获取阿里云oss签名
    async getPolicyTokenInfo() {
      let dates = this.$getDate(3).split("-");
      this.ossParams.uploadDir = `iLaaSWorkbench/team/${dates[0]}/${dates[1]}`;
      let params = JSON.parse(JSON.stringify(this.ossParams));
      params.BucketName = "ilaas-public";
      let res = await getPolicyToken(params);
      if (res.code == 0) {
        this.ossInfo = JSON.parse(res.content);
      }
    },
    // 新增成员
    add() {
      this.dialogInfo.type = 'add';
      this.showDialog = true;
    },
    setMember(info) {
      // console.log(info);
      if (info.id) {
        for (let i = 0; i < this.memberList.length; i++) {
          let item = this.memberList[i];
          if (item.id == info.id) {
            this.memberList[i] = info;
            break
          }
        }
      } else {
        this.memberList.push(info);
      }
    },
    // 保存
    async submit() {
      this.myInfo.lawOfficeLogo = this.showImg;
      this.myInfo.members = this.memberList;
      let res = {};
      if (this.myInfo.id) {
        res = await editTeam(this.myInfo);
      } else {
        res = await addTeam(this.myInfo);
      }
      console.log(res);
      if (res.code == 0) {
        this.getTeamInfo();
        this.$message.success(`保存成功！`);
      }
    },
    // 鼠标移入显示删除图标
    mouseenter(idx) {
      this.isMemberIdx = idx;
    },
    mouseleave() {
      this.isMemberIdx = "";
    },
    // 删除成员
    delMember(idx) {
      this.memberList.splice(this.isMemberIdx, 1);
    },
    // 编辑成员
    edit(info) {
      this.dialogInfo.type = 'edit';
      this.dialogInfo.item = JSON.parse(JSON.stringify(info));
      this.showDialog = true;
    },
  },
};
</script>

<style lang="less" scoped>
.team {
  margin: 0 15px;
  margin-bottom: 30px;
  margin-top: 16px;
  .head-box {
    height: 60px;
    line-height: 60px;
    border-radius: 15px;
    font-size: 14px;
    color: #777;
    background-color: #fff;
    padding-left: 20px;
    margin-bottom: 30px;
  }
  .content-box {
    min-height: 600px;
    padding: 20px;
    border-radius: 15px;
    background-color: #fff;
    .card-box {
      position: relative;
      margin-bottom: 80px;
      .title-p {
        font-size: 14px;
        font-weight: 600;
        color: #333;
      }
      .row-box {
        display: flex;
        align-items: center;
        margin-top: 10px;
        .add-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 160px;
          height: 120px;
          margin-right: 1%;
          margin-bottom: 14px;
          border-radius: 10px;
          // background: #f0f3fb;
          cursor: pointer;
          img {
            width: 30px;
            height: 30px;
            margin-bottom: 10px;
          }
          p {
            font-size: 16px;
            color: #6474c7;
          }
        }
        .file-card {
          .img-box {
            position: relative;
            width: 160px;
            border-radius: 14px;
            overflow: hidden;
            margin-left: 20px;
            img {
              width: 100%;
            }
            .btn-box {
              position: absolute;
              top: 0;
              right: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 100%;
              background-color: rgba(66, 66, 66, 0.5);
              cursor: pointer;
            }
          }
        }
        .file-name {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          i {
            font-size: 36px;
            color: #7097dc;
            cursor: pointer;
          }
        }
        .el-input {
          width: 40%;
          margin-left: 20px;
        }
      }
      .row-box2 {
        align-items: flex-start;
        flex-direction: column;
        .el-textarea {
          width: 70%;
          margin-top: 10px;
        }
      }

      .el-button {
        position: absolute;
        top: 0;
        right: 300px;
      }
      .member-cards {
        margin-top: 30px;
        .member {
          display: flex;
          position: relative;
          width: 70%;
          border: 1px solid #ccc;
          border-radius: 10px;
          padding: 10px;
          box-sizing: border-box;
          margin-bottom: 10px;
          i {
            position: absolute;
            top: -10px;
            right: -10px;
            font-size: 30px;
          }
          .img-box {
            width: 15%;
            img {
              width: 100%;
            }
          }
          .right-box {
            width: 85%;
            padding-left: 20px;
            box-sizing: border-box;
            .name-box {
              span {
                display: inline-block;
                width: 50%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding-right: 10px;
              }
            }
            p {
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: normal;
              word-break: break-all;
            }
          }
        }
      }
    }
  }
  
        .el-button {
          width: 120px;
          height: 40px;
          line-height: 0;
          background: #ffffff;
          font-size: 14px;
          color: #6474c7;
          border-radius: 8px;
          border: 1px solid #6474c7;
        }
}
</style>
