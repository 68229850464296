<template>
  <div class="add-member-dialog">
    <el-dialog
      :title="title"
      center
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="40%"
    >
      <div class="center-box">
        <div class="row-box">
          <p class="label">成员名称：</p>
          <el-input
            v-model="myInfo.name"
            placeholder="请输入成员名称"
          ></el-input>
        </div>
        <div class="row-box">
          <p class="label">职位：</p>
          <el-input v-model="myInfo.position" placeholder="请输入成员职位"></el-input>
        </div>
        <div class="row-box">
          <p class="label">简介：</p>
          <el-input
            v-model="myInfo.profile"
            type="textarea"
            :autosize="{ minRows: 3 }"
            placeholder="请输入简介"
          ></el-input>
        </div>
        <div class="row-box">
          <p class="label">上传头像：</p>
          <div class="add-box" v-show="!showImg">
            <el-upload
              class="avatar-uploader"
              :action="objData.host"
              :data="objData"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              :on-success="handleSuccess"
            >
              <img src="@/assets/serve-report/add-icon.png" alt="" />
              <p>点击上传</p>
            </el-upload>
          </div>
          <div
            v-if="showImg"
            class="file-card"
            @mouseenter="showOperation = true"
            @mouseleave="showOperation = false">
            <div class="img-box">
              <img :src="showImg" alt="" />
              <div class="btn-box" v-if="showOperation">
                <button @click="delFile(fileInfo)">删除</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel">关 闭</button>
        <button class="btn2" @click="submit">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
var OSS = require('ali-oss');
import { getPolicyToken } from "@/FackApi/api/index";
import { mapGetters } from "vuex";
export default {
  name: "addMemberDialog",
  components: {},
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      title: "新增成员",
      dialogVisible: true,
      myInfo: {
        name: "",
        position: "",
        profile: "",
      },
      ossParams: {
        // oss参数
        expireTime: 120,
        uploadDir: "iLaaSWorkbench/team/",
      },
      ossInfo: {},
      filePathStr: "", // 文件上传成功的路径
      objData: {
        OSSAccessKeyId: "",
        policy: "",
        Signature: "",
        key: "",
        host: "",
        dir: "",
      },
      showOperation: false, // 鼠标移入显示操作按钮
      showImg: '',
      fileInfo: {},
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  computed: {
    ...mapGetters({
      userInfo: "index/userInfoState",
    }),
  },
  methods: {
    init() {
      this.getPolicyTokenInfo();
      if (this.info.type == 'edit') {
        this.title = "编辑成员";
        this.myInfo = this.info.item;
        this.showImg = this.myInfo.avatar ? `https://file-public.ilaas.cn/${this.myInfo.avatar}`:'';
        this.filePathStr = this.myInfo.avatar;
      }
    },
    // 上传客户文件前的操作
    async beforeAvatarUpload(file) {
      let newArr = file.name.split(".");
      let fileType = newArr[newArr.length - 1];
      let newFileName = file.name;
      let imgType = ["jpg", "jpeg", "png", "pnge", "dds", "psd", "pdt", "webp", "xmp", "bmp", "svg", "tiff", "ico", ];
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!imgType.includes(fileType)) {
        this.$message.warning("上传文件只能是图片格式!");
        return;
      }
      if (!isLt5M) {
        this.$message.warning("上传文件大小不能超过 5MB!");
        return;
      }
      this.objData = {};
      let data = this.ossInfo;
      this.objData = {
        OSSAccessKeyId: data.accessid,
        policy: data.policy,
        signature: data.signature,
        expire: data.expire,
        host: data.host,
        dir: data.dir,
        key: `${data.dir}/${new Date().getTime()}-${newFileName}`,
      };
      this.filePathStr = this.objData.key;
      this.myInfo.title = newFileName;
      this.myInfo.avatar = this.objData.key;
      this.fileInfo = {
        name: newFileName,
        filePath: this.objData.key,
        imgUrl: `https://file-public.ilaas.cn/${this.objData.key}`, // https://file-public.ilaas.cn ： oss公共桶的链接地址
      };
    },
    // 上传成功
    handleSuccess(response, file, fileList, rowInfo) {
      // console.log(response, file, fileList, rowInfo);
      this.showImg = this.fileInfo.imgUrl;
      // console.log(this.showImg);
    },
    // 删除文件
    delFile(info) {
      this.$confirm(`请确认是否删除头像`)
        .then((_) => {
          this.delOssFile();
        })
        .catch((_) => {});
    },
    async delOssFile() {
        this.fileInfo = {};
        this.myInfo.avatar = '';
      // let client = new OSS({
      //     region: 'oss-cn-shenzhen',
      //     accessKeyId: this.ossInfo.accessid,
      //     accessKeySecret: '5ZUDWAJ0Gz4o52kubRVYCaJRRQRims',
      //     bucket: 'ilaas-public',
      //   })
      // let res = await client.delete(this.filePathStr);
      this.filePathStr = '';
      this.showImg = '';
    },
    // 获取阿里云oss签名
    async getPolicyTokenInfo() {
      let dates = this.$getDate(3).split('-');
      this.ossParams.uploadDir = `iLaaSWorkbench/team/${dates[0]}/${dates[1]}`;
      let params = JSON.parse(JSON.stringify(this.ossParams));
      params.BucketName = "ilaas-public";
      let res = await getPolicyToken(params);
      if (res.code == 0) {
        this.ossInfo = JSON.parse(res.content);
      }
    },
    cancel() {
      this.$parent.showDialog = false;
    },
    async submit() {
      // console.log(this.myInfo);
      // console.log(this.showImg);
      this.$emit("setMember",this.myInfo);
      this.cancel();
    },
  },
};
</script>

<style lang="scss" scoped>
.add-member-dialog {
  ::v-deep .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #eaf5ff;
        span {
          color: #6474c7;
        }
      }
      .el-dialog__body {
        padding: 30px;
        .center-box {
          .row-box {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .label {
              min-width: 72px;
              font-size: 14px;
              color: #606266;
              margin-right: 18px;
              white-space: nowrap;
            }
            .el-input {
              .el-input__inner {
                border-radius: 10px;
                border: 1px solid #d7dbda;
              }
            }
            .add-box {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 24%;
              height: 152px;
              margin-right: 1%;
              margin-bottom: 14px;
              border-radius: 10px;
              background: #f0f3fb;
              cursor: pointer;
              img {
                width: 30px;
                height: 30px;
                margin-bottom: 10px;
              }
              p {
                font-size: 16px;
                color: #6474c7;
              }
            }
            .file-card {
              .img-box {
                position: relative;
                width: 200px;
                border-radius: 14px;
                overflow: hidden;
                img {
                  width: 100%;
                }
                .btn-box {
                  position: absolute;
                  top: 0;
                  right: 0;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 100%;
                  height: 100%;
                  background-color: rgba(66, 66, 66, .5);
                  cursor:pointer;
                }
              }
            }
            .file-name {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              i {
                font-size: 36px;
                color: #7097dc;
                cursor:pointer;
              }
            }
          }
        }
        .btns-box {
          display: flex;
          justify-content: center;
          margin-top: 20px;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #f1f2f1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>
